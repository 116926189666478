import React, { Dispatch, SetStateAction, useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { APIArtistFollowAdd, APIArtistFollowerList, APIArtistFollowingList } from '../../api/ProductAPI';
import { FollowArtistListType } from '../../types/Types';
import profileImage from '../../asset/image/Profile.svg';
import closeImage from '../../asset/image/close.svg';
import { useNavigate } from 'react-router-dom';
import AlertModal from './AlertModal';
import { UserContext } from '../../context/user';
type Props = {
  setModal: Dispatch<SetStateAction<boolean>>;
  idx: string | undefined;
};

function FollwetModal({ setModal, idx }: Props) {
  const navigate = useNavigate();
  const [history, setHistory] = useState(false);
  const [FollowArtistList, setFollowArtistList] = useState<FollowArtistListType[]>([]);
  const [alertType, setAlertType] = useState<string>('');
  const [ShowAlertModal, setShowAlertModal] = useState(false);
  const { user } = useContext(UserContext);

  useEffect(() => {
    getArtistData();
  }, []);

  const getArtistData = async () => {
    const data = {
      page: 1,
      target_idx: idx,
    };
    try {
      if (history) {
        return setHistory(false);
      }
      const { list } = await APIArtistFollowerList(data);
      setFollowArtistList(list);
    } catch (error) {
      console.log(error);
    }
  };

  const UserFollow = async (itemidx: number) => {
    if (user.idx) {
      const data = {
        designer_idx: itemidx,
      };
      try {
        const res = await APIArtistFollowAdd(data);
        if (res.message == '좋아요 완료') {
          setAlertType('Followed');
        } else {
          setAlertType('unFollowed');
        }
        setShowAlertModal(true);
        getArtistData();
      } catch (error) {
        console.log(error);
      }
    } else {
      setShowAlertModal(true);
      setAlertType('Available after Sign up.');
    }
  };

  return (
    <>
      <AlertWrap>
        <ModalBtnWrap>
          <HeaderWrap>
            <p>Follower</p>
            <CloseImage src={closeImage} onClick={() => setModal(false)}></CloseImage>
          </HeaderWrap>
          {FollowArtistList.map((item) => {
            return (
              <FollowItem>
                <FollowUserWrap>
                  <ImageWrap
                    Image={item.designer?.image ? true : false}
                    onClick={() => {
                      setModal(false);
                      navigate(`/MobileProfile/${item?.designer?.idx}`, {
                        state: { idx: item?.designer?.idx, page: 1 },
                      });
                    }}>
                    {item.designer?.image ? (
                      <ProfileImage src={item.designer?.image.file_name} />
                    ) : (
                      <BasicImage src={profileImage} />
                    )}
                  </ImageWrap>
                  <FollowingName>{item?.designer.nickname}</FollowingName>
                </FollowUserWrap>
                {user.idx === Number(idx) && (
                  <UnFollwBtn follow={item?.designer?.isLike} onClick={() => UserFollow(item?.designer?.idx)}>
                    {item.designer?.isLike ? (
                      <UnFollowText>unfollow</UnFollowText>
                    ) : (
                      <UnFollowText>follow</UnFollowText>
                    )}
                  </UnFollwBtn>
                )}
              </FollowItem>
            );
          })}
          <AlertModal
            visible={ShowAlertModal}
            setVisible={setShowAlertModal}
            onClick={() => {
              if (alertType == 'Available after Sign up.') {
                navigate('/signin');
              } else {
                setShowAlertModal(false);
              }
            }}
            text={alertType}
          />
        </ModalBtnWrap>
      </AlertWrap>
    </>
  );
}

const AlertWrap = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: flex-end;
`;
const ModalBtnWrap = styled.div`
  width: 300px;
  display: flex;
  flex-direction: column;
  border-radius: 25px;
  /* padding: 36px 0px 10px 0px; */
  position: absolute;
  z-index: 999;
  background-color: white;
  animation: slideIn 0.3s;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;

const FollowItem = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 20px;
  justify-content: space-between;
`;
const FollowUserWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;
const FollowingName = styled.span`
  font-family: 'Pretendard Variable';
  /* width: 90%; */
  font-size: 12px;
  font-weight: 410;
  margin-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 10px;
  @media only screen and (max-width: 768px) {
    font-size: 9px;
  }
`;

const ImageWrap = styled.div<{ Image: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  cursor: pointer;
  background-color: ${(props) => (props.Image ? 'none' : '#DBDBDB')};
  @media only screen and (max-width: 768px) {
    width: 50px;
    height: 50px;
  }
`;
const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  object-fit: cover;
  /* @media only screen and (max-width: 768px) {
    width: 70px;
  } */
`;
const BasicImage = styled.img`
  width: 50%;
  height: 50%;
  object-fit: contain;
`;

const UnFollwBtn = styled.div<{ follow?: boolean }>`
  padding: 0px 5px;
  font-family: 'Pretendard Variable';
  font-weight: 310;
  display: flex;
  background-color: ${(props) => (props.follow ? '#ffffff' : '#000000')};
  color: ${(props) => (props.follow ? '#505050' : '#ffffff')};
  justify-content: center;
  cursor: pointer;
  align-items: center;
  border: 0.5px solid ${(props) => (props.follow ? '#b4b4b4' : '#000000')};
  border-radius: 7px;
  font-size: 15px;
  white-space: nowrap;
  /* width: 120px; */
  margin: 2px 0;
  @media only screen and (max-width: 768px) {
    height: 30px;
    font-size: 14px;
    width: 94px;
    margin: 1px 0;
  }
`;

const UnFollowText = styled.p`
  cursor: pointer;
  font-family: 'Pretendard Variable';
  /* width: 90%; */
  font-size: 12px;
`;

const CloseImage = styled.img`
  width: 20px;
  height: 20px;
  object-fit: contain;
`;
const HeaderWrap = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
`;
export default FollwetModal;
