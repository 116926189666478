import React, { Dispatch, SetStateAction, useContext } from 'react';
import styled from 'styled-components';
import profileImage from '../../asset/image/Profile.svg';
import { useNavigate } from 'react-router-dom';
import { Pagination, Navigation, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import reporticon from '../../asset/image/threecircle.svg';
import likeOnImage from '../../asset/image/heart_on.svg';
import likeOffImage from '../../asset/image/heart.svg';
import bookmarkOnImage from '../../asset/image/bookon.svg';
import bookmarkOffImage from '../../asset/image/Bookoff.svg';
import rotateLeft from '../../asset/image/right.svg';
import { SnsList, SnsdetailsType, TImage } from '../../types/Types';
import { UserContext } from '../../context/user';

interface CardType {
  item: SnsList;
  UserFollow: (idx: number) => void;
  ReportCheck: (e: string, number: number) => void;
  DeleteSns: (idx: number) => void;
  LikeSns: (idx: number) => void;
  LikeBookMark: (idx: number) => void;
  ReportModal: number;
  setReportModal: Dispatch<SetStateAction<number>>;
  readmore: number;
  setReadMore: Dispatch<SetStateAction<number>>;
  innerWidth: number;
  page: number;
}
function PersonalCard({
  item,
  UserFollow,
  ReportModal,
  setReportModal,
  ReportCheck,
  DeleteSns,
  LikeSns,
  LikeBookMark,
  readmore,
  setReadMore,
  innerWidth,
  page,
}: CardType) {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const navigationPrevRef = React.useRef(null);
  const navigationNextRef = React.useRef(null);

  return (
    <Container>
      <ProfileHeaderWrap LinkUrl={item?.link_url ? true : false}>
        <HeaderLeft>
          <ImageWrap
            onClick={() => {
              navigate(`/MobileProfile/${item.user_idx}`, { state: { idx: item.user_idx, page: 1 } });
            }}
            Image={item.user.image?.file_name ? true : false}>
            {item?.user.image?.file_name ? (
              <ProfileImage src={item?.user.image?.file_name} />
            ) : (
              <BasicImage src={profileImage} />
            )}
          </ImageWrap>
          <NameBox>
            <FlexBox
              onClick={() => {
                navigate(`/MobileProfile/${item.user_idx}`, { state: { idx: item.user_idx, page: 1 } });
              }}>
              <NameText>
                {item?.user.nickname}
                {/* {item?.user.nickname}/{item.idx}/{page} */}
              </NameText>
            </FlexBox>
            <ButtonBox>
              <FollowButtonBox
                follow={item?.user.isLike ? item?.user.isLike : false}
                onClick={() => UserFollow(item.user.idx)}>
                Follow
              </FollowButtonBox>
              <ReportImageWrap
                onClick={(e) => {
                  e.stopPropagation();
                  if (item.idx == ReportModal) {
                    setReportModal(0);
                  } else {
                    setReportModal(item.idx);
                  }
                }}>
                <ImageRotate src={reporticon} />
              </ReportImageWrap>
            </ButtonBox>

            {item.idx == ReportModal && (
              <ReportModalBox height={true}>
                {/* user.idx !== item?.user_idx */}
                {user.idx !== item?.user_idx ? (
                  <>
                    <ReportSelctBox onClick={() => ReportCheck('user', item.user_idx)}>Reporting User</ReportSelctBox>
                    <ReportSelctBox onClick={() => ReportCheck('sns', item.idx)}>Report a post</ReportSelctBox>
                  </>
                ) : (
                  <>
                    <ReportSelctBox
                      onClick={() => {
                        navigate(`/AddPhoto`);
                        sessionStorage.setItem('ModifySns', String(item.idx));
                      }}>
                      Modify a Post
                    </ReportSelctBox>
                    <ReportSelctBox onClick={() => DeleteSns(item.idx)}>Delete a Post</ReportSelctBox>
                  </>
                )}
              </ReportModalBox>
            )}
          </NameBox>
        </HeaderLeft>
      </ProfileHeaderWrap>
      <Swiper
        // install Swiper modules
        modules={[Navigation, Pagination, Scrollbar]}
        // slidesPerView={ProductViews}
        navigation={
          {
            prevEl: navigationPrevRef.current,
            nextEl: navigationNextRef.current,
          }
          // :
          // false
        }
        // spaceBetween={30}
        scrollbar={{ hide: true }}
        pagination={{ clickable: true }}
        style={{ paddingBottom: 2.5 }}>
        {item?.image ? (
          item?.image.map((item: TImage) => {
            return (
              <SwiperSlide key={item.idx}>
                <ImageBox2 innerWidth={innerWidth}>
                  <SliderImage src={item.file_name} />
                </ImageBox2>
              </SwiperSlide>
            );
          })
        ) : (
          <ImageBox2 innerWidth={innerWidth}></ImageBox2>
        )}
        {item?.image && item.image.length >= 2 && (
          <>
            <LeftArrow ref={navigationPrevRef}>
              <RotateImage src={rotateLeft} />
            </LeftArrow>
            <RightArrow ref={navigationNextRef}>
              <img src={rotateLeft} />
            </RightArrow>
          </>
        )}
      </Swiper>
      {item?.link_url && (
        <LinkUrlBox>
          {/* <LinkTitle>{item?.link_title}</LinkTitle> */}
          {/* <LinkUrl
                    onClick={() => {
                      window.open(`https://${item?.link_url}`, '_blank');
                    }}> */}
          <LinkUrl
            onClick={() => {
              if (item?.link_url.includes('https://')) {
                window.open(`${item?.link_url}`, '_blank');
              } else {
                window.open(`https://${item?.link_url}`, '_blank');
              }
            }}>
            View Website
            {/* {item?.link_url} */}
          </LinkUrl>
        </LinkUrlBox>
      )}
      <LikeButtonWrap>
        <LikeBox>
          <LikeButton onClick={() => LikeSns(item.idx)} src={item?.isLike ? likeOnImage : likeOffImage} />
          <BookMarkButton
            onClick={() => LikeBookMark(item.idx)}
            src={item?.isBookmark ? bookmarkOnImage : bookmarkOffImage}
          />
        </LikeBox>
      </LikeButtonWrap>
      <DescriptionWrap LinkUrl={item?.link_url ? true : false} readmore={readmore == item.idx}>
        <BoldSpan>{item?.user.nickname}</BoldSpan>
        <br />
        {item?.about}
      </DescriptionWrap>
      <ReadMoreWrap>
        {readmore !== item.idx && item?.about.length > 30 ? (
          <ReadMore
            onClick={() => {
              setReadMore(item.idx);
            }}>
            Read More
          </ReadMore>
        ) : (
          ''
        )}
      </ReadMoreWrap>
    </Container>
  );
}

const Container = styled.div`
  max-width: 768px;
  width: 100%;
  margin: 0 auto;
  flex: 1;
  @media only screen and (max-width: 768px) {
  }
`;

const ProfileImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  border: 1px solid #e0e0e0;
  object-fit: cover;
`;
const LikeButtonWrap = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
`;
const LikeBox = styled.div`
  display: flex;
  align-items: center;
  margin: 20px 20px 0;
  @media only screen and (max-width: 768px) {
    margin: 20px 20px 0;
  }
`;
const DescriptionWrap = styled.div<{ LinkUrl: boolean; readmore: boolean }>`
  font-family: 'Pretendard Variable';
  font-weight: 310;
  font-size: 16px;
  min-height: 50px;
  white-space: pre-line;
  max-width: 100%;
  word-break: break-all;
  display: ${(props) => (props.readmore ? 'block' : '-webkit-box')};
  -webkit-line-clamp: ${(props) => (props.readmore ? 20 : 3)}; // 원하는 라인수
  -webkit-box-orient: vertical;
  overflow: hidden;
  /* white-space:nowrap; */
  text-overflow: ellipsis;
  margin: 25px 38px 0px 22px;
  /* margin-bottom: ${(props) => (props.LinkUrl ? 120 : 150)}px; */
  text-align: start;
  @media only screen and (max-width: 768px) {
    box-sizing: border-box;
    /* width: 90%; */
    margin: 25px 38px 0px 22px;
    min-height: 44px;
    /* margin-bottom: ${(props) => (props.LinkUrl ? 21 : 51)}px; */
    font-size: 12px;
  }
`;

const ReadMoreWrap = styled.div`
  text-align: start;
  margin-bottom: 120px;
  margin-left: 25px;
  @media only screen and (max-width: 768px) {
    box-sizing: border-box;
    /* width: 90%; */
    margin: 0px 38px 0px 22px;
    min-height: 44px;
    font-size: 12px;
  }
`;
const ReadMore = styled.span`
  font-family: 'Pretendard Variable';
  font-weight: 360;
  font-size: 14px;
  cursor: pointer;
  text-align: start;
  text-decoration: underline;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;
const BoldSpan = styled.span`
  font-family: 'Pretendard Variable';
  font-weight: 460;
  font-size: 16px;
`;
const LikeButton = styled.img`
  width: 23px;
  height: 19px;
  object-fit: contain;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    width: 23.28px;
    height: 19.49px;
  }
`;
const BookMarkButton = styled.img`
  width: 15px;
  height: 19px;
  margin-left: 24px;
  object-fit: contain;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    width: 15.54px;
    height: 19.49px;
  }
`;

const LinkUrlBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 5px 0;
  background: #000000;
  &:hover {
    background-color: #5b5b5b;
  }
`;
const LinkTitle = styled.a`
  font-family: 'Pretendard Variable';
  font-weight: 410;
  color: #ffffff;
  font-size: 14px;
`;
const LinkUrl = styled.a`
  font-family: 'Pretendard Variable';
  font-size: 13px;
  color: #ffffff;
  font-weight: 310;
  text-decoration: none;
  cursor: pointer;
`;

const ImageBox2 = styled.div<{ innerWidth: number }>`
  width: 768px;
  height: 768px;
  /* background:#c7c7c7; */
  /* max-height:800px; */
  object-fit: contain;
  /* overflow: hidden; */
  /* background-color:aqua; */
  /* aspect-ratio: 0.8; */
  @media only screen and (max-width: 768px) {
    width: ${(props) => props.innerWidth}px;
    height: ${(props) => props.innerWidth}px;
  }
`;

const SliderImage = styled.img`
  object-fit: cover;
  height: 100%;
  max-height: 1096px;
  max-width: 100%;
  @media only screen and (max-width: 768px) {
    width: 100%;
    height: 100%;
  }
`;

const ProfileHeaderWrap = styled.div<{ LinkUrl: boolean }>`
  display: flex;
  justify-content: space-between;
  margin: 0px 20px 0px;
  margin-top: 60px;
  margin-bottom: ${(props) => (props.LinkUrl ? 20 : 40)}px;
`;
const HeaderLeft = styled.div`
  width: 100%;
  display: flex;

  @media only screen and (max-width: 768px) {
  }
`;

const NameBox = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const NameText = styled.p`
  font-family: 'Pretendard Variable';
  font-size: 16px;
  font-weight: 410;
  text-align: start;
  line-height: 31px;
  margin: 0 10px 0 0;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;

const FollowButtonBox = styled.div<{ follow: boolean }>`
  font-family: 'Pretendard Variable';
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  cursor: pointer;
  background-color: ${(props) => (props.follow ? '#000000' : '#ffffff')};
  color: ${(props) => (props.follow ? '#ffffff' : '#000000')};
  border: 1px solid #c7c7c7;
  border-radius: 4.93px;
  font-size: 12px;
  width: 90px;
  height: 38px;
  font-weight: 300;
  white-space: nowrap;
  @media only screen and (max-width: 768px) {
    width: 60px;
    height: 25px;
    font-size: 10px;
  }
`;

const FlexBox = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const ImageWrap = styled.div<{ Image: boolean }>`
  width: 65px;
  height: 65px;
  cursor: pointer;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  aspect-ratio: 1;
  background-color: ${(props) => (props.Image ? 'none' : '#DBDBDB')};
  @media only screen and (max-width: 768px) {
    width: 36px;
    height: 36px;
  }
`;

const BasicImage = styled.img`
  width: 50%;
  height: 50%;
  object-fit: contain;
`;
const ReportImageWrap = styled.div`
  padding: 0 1px;
  margin-left: 0px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;
const ReportModalBox = styled.div<{ height: boolean }>`
  width: 180px;
  height: ${(props) => (props.height ? 110 : 55)}px;
  z-index: 98;
  cursor: pointer;
  background: #ffff;
  box-shadow: 2px 2px 5px #4e4e4e;
  position: absolute;
  right: 0;
  bottom: ${(props) => (props.height ? -110 : -55)}px;
`;
const ReportSelctBox = styled.div`
  font-family: 'Pretendard Variable';
  font-size: 15px;
  font-weight: 360;
  height: 55px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const ImageRotate = styled.img`
  width: 24px;
  height: 6px;
  aspect-ratio: 1;
  margin: 0 10px;
  cursor: pointer;
  transform: rotate(90deg);
  @media only screen and (max-width: 768px) {
    width: 14px;
    height: 3px;
  }
`;
const ButtonBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  /* background-color:black; */
`;

const RotateImage = styled.img`
  transform: rotate(180deg);
`;
const LeftArrow = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  position: absolute;
  top: 47%;
  left: 20px;
  width: 47px;
  height: 47px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 99999;
  @media only screen and (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
`;
const RightArrow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  top: 47%;
  right: 20px;
  width: 47px;
  height: 47px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.5);
  z-index: 99999;
  @media only screen and (max-width: 768px) {
    width: 30px;
    height: 30px;
  }
`;
export default PersonalCard;
