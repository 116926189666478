import React, { useCallback, useContext, useEffect, useLayoutEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import arrDownImage from '../../asset/image/arr_down.png';
import { Modal, Select } from '@mantine/core';
import { SnsList, SnsdetailsType, TImage } from '../../types/Types';
import AlertModal from '../../components/Modal/AlertModal';
import { UserContext } from '../../context/user';
import { useRef } from 'react';
import { createBrowserHistory } from 'history';
import { removeHistory } from '../../components/Layout/Header';
import {
  APIArtistFollowAdd,
  APIBookMarkLike,
  APIBookMarkLikeList,
  APIFollowersProductList,
  APISnsCount,
  APISnsDetails,
  APISnsLike,
  APISnsLikeList,
  APISnsList,
} from '../../api/ProductAPI';
import { APISnsDelete, APISnsReport, APIUserReport } from '../../api/UserAPI';
import { NoDoubleEmptySpace } from '../../util/Reg';
import 'swiper/css';
import 'swiper/css/pagination';
import './PersonalPage.css';
import PersonalCard from './PersonalCard';

export const ReportReasonList = [
  { name: 'ALL', label: '신고사유', value: '' },
  { name: '0', label: '욕설, 비방, 인신공격 게재', value: '0' },
  { name: '1', label: '영리목적 / 홍보성의 광고 내용', value: '1' },
  { name: '2', label: '개인정보 유출위험', value: '2' },
  { name: '3', label: '게시글 도배', value: '3' },
  { name: '4', label: '음란/선정성 불법 정보 노출', value: '4' },
  { name: '5', label: '기타', value: '5' },
];

function PersonalPage() {
  const navigate = useNavigate();
  const history = createBrowserHistory();
  const location = useLocation();
  const { user } = useContext(UserContext);
  const { name } = useParams();
  const [ShowAlertModal, setShowAlertModal] = useState(false);
  const [alertType, setAlertType] = useState<string>('');
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [reason, setReason] = useState<string | null>('');
  const [reasonText, setReasonText] = useState<string>('');
  const [readmore, setReadMore] = useState(0);
  const [ReportModal, setReportModal] = useState(0);
  const [ShowReportModal, setShowReportModal] = useState(false);
  const [ReportType, setReportType] = useState<{ reportType: number; reportIdx: number }>({
    reportType: 0,
    reportIdx: 0,
  });
  const [ReportNumber, setReportNumber] = useState<number>(0);
  const [ip, setIp] = useState();

  const [SnsList, setSnsList] = useState<SnsList[]>([]);
  const interSectRef = useRef(null);
  const [page, setPage] = useState<number>(1);
  // console.log('user', user);
  // console.log('location', location);
  // console.log('name', name);

  // const [page, setPage] = useState<number>(location.state.page);
  const getproductList = async (page: number) => {
    const categ = sessionStorage.getItem('FeedTabCategory');

    const data = {
      page: page,
      category: categ ? categ : '1',
      keyword: '',
      is_random: 'Y',
    };
    try {
      const { list, total } = await APISnsList(data);
      // setTotal(total);
      if (page > 1) {
        setSnsList((prev) => [...prev, ...list]);
      } else {
        setSnsList(list);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getBookMarkList = async (page: number) => {
    const data = {
      page: page,
      keyword: '',
    };
    try {
      const { list, total } = await APIBookMarkLikeList(data);
      if (page > 1) {
        setSnsList((prev) => [...prev, ...list]);
      } else {
        setSnsList(list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getFollowSnsData = async (page: number) => {
    const data = {
      page: page,
      keyword: '',
    };
    try {
      const { list, total } = await APIFollowersProductList(data);
      if (page > 1) {
        setSnsList((prev) => [...prev, ...list]);
      } else {
        setSnsList(list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getLikeSnsData = async (page: number) => {
    const data = {
      page: page,
    };
    try {
      const { list } = await APISnsLikeList(data);
      if (page > 1) {
        setSnsList((prev) => [...prev, ...list]);
      } else {
        setSnsList(list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getMyFeedData = async (page: number) => {
    const data = {
      page: page,
      user_idx: location.state.idx,
    };
    try {
      const { list } = await APISnsList(data);

      if (page > 1) {
        setSnsList((prev) => [...prev, ...list]);
      } else {
        setSnsList(list);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const UserFollow = async (idx: number) => {
    if (user.idx == idx) return setShowAlertModal(true), setAlertType(`You can't follow yourself`);
    if (user.idx) {
      const data = {
        designer_idx: idx,
      };
      try {
        const res = await APIArtistFollowAdd(data);
        if (res.message == '좋아요 완료') {
          setAlertType('Followed');
        } else {
          setAlertType('unFollowed');
        }
        setShowAlertModal(true);

        const newList = SnsList.map((item) =>
          item.user_idx === idx ? { ...item, user: { ...item.user, isLike: !item.user.isLike } } : { ...item },
        );
        setSnsList(newList);
      } catch (error) {
        console.log(error);
      }
    } else {
      setShowAlertModal(true);
      setAlertType('Available after Sign up.');
    }
  };

  const LikeSns = async (idx: number) => {
    if (user.idx == idx) return setShowAlertModal(true), setAlertType('This is my Post.');
    if (user.idx) {
      const data = {
        sns_idx: idx,
      };
      try {
        const res = await APISnsLike(data);
        if (res.message == '좋아요 완료') {
          setAlertType('Liked');
        } else {
          setAlertType('unLiked');
        }
        setShowAlertModal(true);
        const newList = SnsList.map((item) => (item.idx === idx ? { ...item, isLike: !item.isLike } : { ...item }));
        setSnsList(newList);
      } catch (error) {
        console.log(error);
      }
    } else {
      setShowAlertModal(true);
      setAlertType('Available after Sign up.');
    }
  };

  const LikeBookMark = async (idx: number) => {
    if (user.idx == idx) return setShowAlertModal(true), setAlertType('This is my Post.');
    if (user.idx) {
      const data = {
        sns_idx: idx,
      };
      try {
        const res = await APIBookMarkLike(data);
        if (res.message == '좋아요 완료') {
          setAlertType('BookMarked');
        } else {
          setAlertType('unBookMarked');
        }
        setShowAlertModal(true);
        const newList = SnsList.map((item) =>
          item.idx === idx ? { ...item, isBookmark: !item.isBookmark } : { ...item },
        );
        setSnsList(newList);
      } catch (error) {
        console.log(error);
      }
    } else {
      setShowAlertModal(true);
      setAlertType('Available after Sign up.');
    }
  };

  const ReportSns = async (idx: number) => {
    if (reason === '') return setShowAlertModal(true), setAlertType('select the reason for reporting.');
    if (reason === '기타' && !reasonText) return setAlertType('enter the report details.'), setShowAlertModal(true);
    if (reason) {
      for (let i = 0; i < ReportReasonList.length; i++) {
        if (ReportReasonList[i].value.includes(reason)) {
          break;
        }
        setReportNumber(i);
      }
    }
    const data = {
      reason: reason,
      reason_etc: reasonText,
      target_idx: idx,
    };
    try {
      const res = await APISnsReport(data);
      setShowReportModal(false);
      setReasonText('');
      setShowAlertModal(true);
      setAlertType('Reported.');
      // console.log(res);
    } catch (error) {
      setShowReportModal(false);
      setShowAlertModal(true);
      setAlertType('Already Reported.');
      console.log(error);
    }
  };

  const ReportUser = async (idx: number) => {
    if (reason === '') return setShowAlertModal(true), setAlertType('select the reason for reporting.');
    if (reason === '기타' && !reasonText) return setAlertType('enter the report details.'), setShowAlertModal(true);
    if (reason) {
      for (let i = 0; i < ReportReasonList.length; i++) {
        if (ReportReasonList[i].value.includes(reason)) {
          break;
        }
        setReportNumber(i);
      }
    }
    const data = {
      reason: ReportNumber,
      reason_etc: reasonText,
      target_idx: idx,
    };
    try {
      const res = await APIUserReport(data);
      setShowReportModal(false);
      setShowAlertModal(true);
      setAlertType('Reported.');
      setReasonText('');
    } catch (error) {
      setShowReportModal(false);
      setShowAlertModal(true);
      setAlertType('Already Reported.');
      // console.log(error);
    }
  };

  const DeleteSns = async (idx: number) => {
    if (!user.idx) return setShowAlertModal(true), setAlertType('Available after Sign up.');
    // if (!reason) return(setShowAlertModal(true),setAlertType('select the reason for reporting.'))
    const data = {
      idx: idx,
    };
    try {
      const res = await APISnsDelete(data);
      setShowReportModal(false);
      setShowAlertModal(true);
      setAlertType('Deleted');
    } catch (error) {
      setShowReportModal(false);
      setShowAlertModal(true);
      setAlertType('failed');
      // console.log(error);
    }
  };

  const SNSCount = async () => {
    const uuid = sessionStorage.getItem('_u');
    const data = {
      uuid: uuid,
      res: ip,
      sns_idx: location.state.idx,
    };
    try {
      const res = await APISnsCount(data);
    } catch (error) {
      console.log(error);
    }
  };

  const ReportCheck = (e: string, number: number) => {
    if (!user.idx) return setShowAlertModal(true), setAlertType('Available after Sign up.');
    setShowReportModal(true);
    setReportModal(0);
    if (e == 'user') {
      setReportType({ reportType: 1, reportIdx: number });
    } else {
      setReportType({ reportType: 2, reportIdx: number });
    }
  };

  useEffect(() => {
    fetch('https://geolocation-db.com/json/')
      .then((response) => response.json())
      .then((data) => {
        setIp(data.IPv4);
      })
      .catch((error) => console.log(error));
  }, []);
  useEffect(() => {
    const resizeListener = () => {
      setInnerWidth(window.innerWidth);
    };
    window.addEventListener('resize', resizeListener);
  }, [innerWidth]);

  const handleObserver = useCallback((entries: any) => {
    const target = entries[0];
    if (target.isIntersecting) {
      setPage((prev) => prev + 1);
    }
  }, []);

  const options = {
    root: null, //기본 null, 관찰대상의 부모요소를 지정
    rootMargin: '100px', // 관찰하는 뷰포트의 마진 지정
    threshold: 1.0, // 관찰요소와 얼만큼 겹쳤을 때 콜백을 수행하도록 지정하는 요소
  };

  // useEffect(() => {
  //   if (page > 1) getproductList(page);
  // }, [page]);

  useEffect(() => {
    const observer = new IntersectionObserver(handleObserver, options);
    if (interSectRef.current) observer.observe(interSectRef.current);
    return () => observer.disconnect();
  }, [handleObserver]);

  useLayoutEffect(() => {
    const SNSy = sessionStorage.getItem('SNSy');
    const listType = sessionStorage.getItem('removeSNSHistory');
    let Heightt = innerWidth > 768 ? 768 + 431 : innerWidth + 300;
    const scrollY = (location.state.index - Math.floor(location.state.index / 10) * 10) * Heightt;
    if (SnsList.length > 0 && SNSy) {
      setTimeout(() => {
        window.scrollTo({
          top: scrollY,
          behavior: 'auto',
        });
        sessionStorage.removeItem('SNSy');
      }, 10);
    }
  }, [SnsList]);

  useEffect(() => {
    const listType = sessionStorage.getItem('removeSNSHistory');
    if (listType == 'SnsBookMark') {
      getBookMarkList(Math.floor(location.state.index / 10) + 1);
    } else if (listType == 'SnsFollow') {
      getFollowSnsData(Math.floor(location.state.index / 10) + 1);
    } else if (listType == 'SnsFeed') {
      getproductList(Math.floor(location.state.index / 10) + 1);
    } else if (listType == 'SnsLike') {
      getLikeSnsData(Math.floor(location.state.index / 10) + 1);
    } else if (listType == 'MyFeed') {
      getMyFeedData(Math.floor(location.state.index / 10) + 1);
    }
  }, []);

  useEffect(() => {
    const backCheck = history.listen(({ location, action }) => {
      if (action === 'POP') {
      }
    });
    return backCheck;
  }, []);

  useEffect(() => {
    if (ip) {
      SNSCount();
    }
  }, [ip]);

  return (
    <ContainerWrap id="productContainer" onClick={() => setReportModal(0)}>
      {SnsList &&
        SnsList.length > 0 &&
        SnsList.map((item, index) => {
          return (
            <PersonalCard
              key={index}
              item={item}
              UserFollow={UserFollow}
              ReportModal={ReportModal}
              setReportModal={setReportModal}
              ReportCheck={ReportCheck}
              DeleteSns={DeleteSns}
              LikeSns={LikeSns}
              LikeBookMark={LikeBookMark}
              readmore={readmore}
              setReadMore={setReadMore}
              innerWidth={innerWidth}
              page={page}
            />
          );
        })}
      <Modal
        opened={ShowReportModal}
        onClose={() => setShowReportModal(false)}
        overlayOpacity={0.5}
        size="auto"
        centered
        withCloseButton={false}>
        <ModalTitle>Do you want to report the{ReportType.reportType == 1 ? 'user' : 'post'}?</ModalTitle>
        <Select
          rightSection={<DownIcon src={arrDownImage} />}
          styles={(theme) => ({
            rightSection: { pointerEvents: 'none' },
            root: { width: '100%' },
            item: {
              '&[data-selected]': {
                '&, &:hover': {
                  backgroundColor: '#121212',
                  color: '#fff',
                },
              },
            },
          })}
          variant="unstyled"
          value={reason}
          data={ReportReasonList}
          onChange={setReason}
        />
        {reason !== '5' ? (
          <ModalContentBox1
            disabled
            value={reasonText}
            onChange={(e) => setReasonText(NoDoubleEmptySpace(e.target.value))}></ModalContentBox1>
        ) : (
          <ModalContentBox
            value={reasonText}
            onChange={(e) => setReasonText(NoDoubleEmptySpace(e.target.value))}></ModalContentBox>
        )}

        <ButtonWrap visible={true} marginT={37} marginB={37}>
          <WhiteButton onClick={() => setShowReportModal(false)}>
            <WhiteButtonText>Cancel</WhiteButtonText>
          </WhiteButton>
          <BlackButton
            onClick={() =>
              ReportType.reportType == 1 ? ReportUser(ReportType.reportIdx) : ReportSns(ReportType.reportIdx)
            }>
            <BlackButtonText>OK</BlackButtonText>
          </BlackButton>
        </ButtonWrap>
      </Modal>
      <InterView ref={interSectRef} />
      <AlertModal
        visible={ShowAlertModal}
        setVisible={setShowAlertModal}
        onClick={() => {
          if (alertType == 'Available after Sign up.') {
            removeHistory();
            navigate('/signin');
          } else if (alertType == 'Deleted') {
            navigate(-1);
          } else {
            setShowAlertModal(false);
          }
        }}
        text={alertType}
      />
    </ContainerWrap>
  );
}

const ContainerWrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex: 1;
  /* margin-bottom:100px; */
`;

const DownIcon = styled.img`
  width: 14px;
  height: 14px;
  cursor: pointer;
`;

const ModalTitle = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Pretendard Variable';
  font-size: 17px;
  color: #000000;
  font-weight: 510;
  text-align: center;
  margin-bottom: 20px;
  @media only screen and (max-width: 768px) {
    font-size: 14px;
  }
`;
const ModalContentBox = styled.textarea`
  width: 400px;
  height: 200px;
  border: 1px solid #121212;
  padding: 15px;
  overflow-y: scroll;
  margin: 5px 0;
  resize: none;
  font-size: 14px;
  font-weight: 410;
  color: #121212;
  outline: 0;
  line-height: 25px;
  margin-top: 10px;
  background-color: #fff;
  -webkit-text-fill-color: #121212;
  opacity: 1;

  @media only screen and (max-width: 768px) {
    width: 260px;
    height: 150px;
    font-size: 12px;
    line-height: 20px;
    margin-top: 10px;
  }
`;
const ModalContentBox1 = styled.textarea`
  width: 400px;
  resize: none;
  background: none;
  border: none;
  @media only screen and (max-width: 768px) {
    width: 260px;
  }
`;

const ButtonWrap = styled.div<{ marginT?: number; marginB?: number; visible?: boolean }>`
  display: flex;
  width: 100%;
  margin: 37px auto;
  min-height: 55px;

  @media only screen and (max-width: 768px) {
    min-height: 45px;
  }
`;

const BlackButton = styled.div`
  background-color: rgb(73, 73, 73);
  border: 1px solid #131313;
  cursor: pointer;
  border-radius: 5px;
  flex: 1;
  display: flex;

  justify-content: center;
  align-items: center;
  @media only screen and (max-width: 768px) {
  }
`;
const BlackButtonText = styled.span`
  font-family: 'Pretendard Variable';
  color: #ffffff;
  font-size: 14px;
  font-weight: 360;
  cursor: pointer;
  @media only screen and (max-width: 768px) {
    font-size: 12px;
  }
`;

const WhiteButton = styled(BlackButton)`
  margin-right: 10px;
  background-color: #ffffff;
  flex: 1;
`;

const WhiteButtonText = styled(BlackButtonText)`
  color: #121212;
`;

const InterView = styled.div`
  height: 150px;
`;

export default PersonalPage;
